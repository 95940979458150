<template>
  <div class="doc-outter">
    <div class="doc-title">
        <h3>网上购物须知</h3>
    </div>
    <div class="doc-content">
      <div class="noteCont m_t_30">
        <div class="wrapCont">
          <p class="m_b_15" style="font-size: 14px; margin-left: -5px">
            尊敬的旅客：
          </p>
          <p>
            欢迎您选乘幸福航空的航班。为了方便您的旅行，请您在购票前仔细阅读本旅客须知。您也可以通过致电我们的服务热线4008680000查询和了解我们的运输总条件。
          </p>
          <p>
            <b>1.客票:</b
            >客票是我们或者我们的授权代理人填开的电子客票，电子客票做为有效的报销凭证。
          </p>
          <p>
            <b>2.客票有效期及使用规定：</b
            >机票的有效期为一年，有效期从航班飞行之日开始计算；如机票未使用，从出票次日零时开始计算，一年内有效。超过有效期，机票作废，作废的机票不能再办理改期、升舱、退票。客票必须按照购买的填开顺序依次使用，否则客票视为作废！
          </p>
          <p><b>3.票价：</b></p>
          <p>
            票价是由出发地机场至目的地机场的航空运输价格，不包括机场与市区之间的地面运输费用。
          </p>
          <p><b>特殊票价：</b></p>
          <p>
            （1）儿童票价：已满两周岁未满12周岁。儿童票按成人全票价的50%付费。
          </p>
          <p>
            （2）婴儿票价：未满两周岁。按成人全票价的10%付费，不单独占一座位，每一成人旅客只能携带一名婴儿。
          </p>
          <p>注：儿童、婴儿的年龄计算以旅客出行日期为准。</p>
          <p>
            <b>4.旅客姓名：</b
            >机票只限购票时登记的乘机人使用，不得转让或修改姓名。如乘机人姓名、证件号错误，需办理退票后，重新购票，所退机票须按相关规定收取退票手续费。购买电子客票时，请保证您填写的乘机人信息准确无误，此证件要与办理乘机手续和通过安全检查时的证件相同，否则会给乘机人的出行带来不便。另外，请您留下真实、有效的联系电话，以便在航班变更等情况时能及时与您联系。
          </p>
          <p>
            <b>5.改期、升舱、签转：</b
            >所购机票有其特定的变更与签转规定，如您购票后需要办理改期、升舱、签转手续，请联系幸福航空客服中心4008680000。您在购票时请仔细阅读所订舱位的有关使用条件及签转退票规定，以免给您带来不必要的损失。
          </p>
          <p>
            <b>6.退票：</b
            >您购票后如要求退票，应在客票有效期内（自购买之日起1年内）在原购票地点凭您的有效旅行证件办理。
          </p>
          <p>
            <b>7.行程单索取：</b
            >行程单做为有效报销凭证，如需报销请您联系原出票地索取发票，幸福航空网站及客服购买客票请您在乘机后7日内拨打4008680000咨询并领取，我们将会采用快递到付形式为您提供发票，逾期我们将无法打印。
            幸福航空官网购买的保险发票与行程单合二为一，如行程单过期无法开具，旅客需自行联系保险公司单独开具保费发票。保险公司电话：027-95515。
          </p>
          <p>
            <b>8.特殊旅客：</b
            >无成人陪伴儿童/老人、残障者、孕妇、患病者或其他在旅途中需要特殊礼遇或照料并符合一定运输条件的旅客，欲乘坐我们的航班，我们建议您至少在乘机72小时之前咨询幸福航空客服电话4008680000并提出申请，经我们同意后，方予承运。如果旅客购票时未通知我们或者未出示相关证明，我们有权拒绝运输。特殊旅客乘机当日必须提前2小时前往机场办理乘机手续。
          </p>
          <div class="zhu_wrap">
            <p><b>注：</b></p>
            <p>（1）无人陪儿童：五周岁（含）至十二周岁（含）以下儿童；</p>
            <p>
              婴儿：年龄未满2周岁的婴儿；
              （拒绝承运出生14天内婴儿，超过14天的早产婴儿必须出示《病情诊断证明书》）
            </p>
            <p>
              （2）患病旅客（含孕妇）乘坐我公司航班必须出示48小时内由三级及以上医院（境外指诊所、医疗中心以外的医院）出具盖章并附有主治医师意见的《诊断证明书》。患重疾病旅客（心血管、癌症、急性外伤等）出示的《诊断证明书》必须在24小时内有效。
            </p>
            <p>
              （3）怀孕不足32周的孕妇乘机，除医生诊断不适应乘机者外，按一般旅客接受运输，建议携带孕期证明。怀孕超过32周（含）但不足35周的健康孕妇乘机必须提供《诊断证明书》，《诊断证明书》的内容包括旅客姓名、年龄、怀孕时期、预产期、航程和日期、适宜乘机以及在机上需要提供特殊照料的事项，经幸福航空同意后方可购票乘机。拒绝承运怀孕35周（含）以上孕妇旅客、有早产症状孕妇旅客和分娩7天内产妇旅客
            </p>
            <p>
              （4）军残、警残可通过网站“军警残预订入口”进入订票，如有疑问，可致电客服热线4008680000咨询。
            </p>
          </div>
          <p>
            <b>9. 乘机安全须知：</b
            >在乘机过程中请您遵守机组人员的专业指引，为了防止飞行中因气流变化引起的颠簸对您造成伤害,请您在乘机过程中全程系好安全带。为了确保飞行安全，充电宝请保持全程关机状态，当您在机上使用手机等电子设备时，请确认该电子设备为飞行模式。飞行上升、下降、颠簸时，请您在座位上坐好并确认安全带已扣好，期间请不要起身在客舱中走动或开启头顶上方行李架，洗手间会暂停使用，同时将暂停客舱服务。
          </p>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.wrapCont p {
  margin-bottom: 8px;
}
.zhu_wrap {
  margin-bottom: 8px;
}
.zhu_wrap p {
  margin-bottom: 0;
  padding-left: 12px;
  line-height: 180%;
}
</style>
